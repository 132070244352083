export const pdx_env = {
  REACT_APP_API_URL: "https://sea.urbanform.us/api",
  REACT_APP_MAPBOX_ACCESS_TOKEN:
    "pk.eyJ1IjoicXZ0MjgyIiwiYSI6ImNrZmtxOWdveDB2ZXkyeG56NzR0aW9jbXUifQ.Plxub9XCzhhAbZ3MJbAXMw",
  REACT_APP_MAPBOX_STYLE_URL_BASIC:
    "mapbox://styles/qvt282/clsxvexm0004a01o818l76cja",//multi-region-style
  REACT_APP_MAPBOX_STYLE_URL_SATELLITE:
    "mapbox://styles/qvt282/clsxvfe6z003301raf48b8fpw",
  REACT_APP_MAPBOX_STYLE_URL_MBENVELOPE:
    "mapbox://styles/qvt282/clsxvf9t0004c01py2myf9doe",
  REACT_APP_MAPBOX_LAYER_NAME: "pdxbvn_taxlots",
  REACT_APP_STRIPE_KEY:
    "pk_test_51J3keRKwuRPRmetLYA1Zgpg1T50OlJj7L5U7p60MB7hvR8VaaXhoDk0llLi95tOaWUU77c4rNEx7fa22nyEStrxD00X4iN7RXg",
  REACT_APP_REGION: "portland",
  REACT_APP_BBOX: "-124.566244, 41.991794, -116.463504, 46.292035",
  REACT_APP_MAP_CENTER: "-122.676,45.530",
    REACT_APP_SUBREGION_LAYER:"",
  REACT_APP_SUB_REGION: "beaverton",
  REACT_APP_SUB_CITY_NAME: "Beaverton, Oregon",
  REACT_APP_CITY_NAME: "Portland, Oregon",
  REACT_APP_CITIES_LIST:
    '{ "portland": [], "seattle": [], "san_diego": [], "austin": [] }',
  REACT_APP_PROXIMITY: "-122.675, 45.518", 
  REACT_APP_MB_TILES_LIST:"taxlots-fill",
  REACT_APP_ZOOM_LEVEL:10.5
};
